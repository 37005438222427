import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';

//Components
import Modal from '../../../../components/Modal';
import Content from '../partials';

//Styles
import styles from './ModalNewLoyaltyProgramm.styl';

const cx = classNames.bind(styles);

const ModalNewLoyaltyProgramm = ({ isOpen, setIsOpen }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  let timer = false;

  const activateTimer = () => {
    setTimeout(() => {
      timer = true;
    }, 2000);
  };

  activateTimer();

  const onCloseModal = useCallback(() => {
    timer && setIsOpen(false);
  }, [timer]);

  useEffect(() => {
    window.localStorage.setItem('showLoyaltyModal', 'true');
  }, []);

  return (
    <Modal
      className={cx('ModalContainer__modal')}
      isOpen={isOpen}
      onClose={onCloseModal}
    >
      <Content />
      <button className={cx('ModalContainer__order-button')}
              onClick={onCloseModal}>
        {intl.formatMessage({ id: 'button.understandably' })}
      </button>
    </Modal>
  );
};

export default ModalNewLoyaltyProgramm;
