import React, {lazy, Suspense, useEffect, useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { currentLoyaltySelectorForNewPL, daysResetStatus } from "../../../state/modules/user/selectors";

// Components
import Container from "../../../components/Container";
import HatikoBanner from "../../../components/PromotionHatico/HatikoBanner/HatikoBanner";
import PromoArtefact from "../PromoGame/PromoArtefact";
import { GenderLink } from '../../GenderPromo/GenderLink/GenderLink';

// Partials
import useHome from "../useHome";
import ThanksForOrderingLink from "../ThanksForOrderingLink";
import DeliveryAlert from "../DeliveryAlert";
import MainSlider from "../MainSlider";
import MainSidebar from "../MainSidebar";
import SEO from "../SEO";
import StockGoods from "../StockGoods/DesktopView";
import FreeSaucesPanel from "../FreeSaucesPanel/DesktopView";
import CategoryIcons from "../CategoryIcons";
import ProductList from "../ProductList/DesktopView";
import Header from "../Header/DesktopView";
import PopupGeneral from "../PopupGeneral";
import ModalNotAuth from "../ModalNotAuth/DesktopView";
import ModalNotEnough from "../ModalNotEnought/DesktopView";
import ModalNewLoyaltyProgramm from "../ModalNewLoyaltyProgramm/DesktopView";

// Actions
import { changeVpnModal } from "../../../state/modules/homeModals/actions";
import { fetchProfile } from "../../../state/modules/user/actions";
// Icons
import VPNIcons from "../../../icons/icon-vpn.svg";

// Styles
import styles from "./Home.styl";
import PopupPromoGame from "../PromoGame/PopupPromoGame";
import FlagsDecor from "../PromoGame/FlagsDecor/FlagsDecor";
import {NotAllowedCityModal} from "../NotAllowedCityModal/DesktopView";

// Selectors
import {
  isGenderPromo,
  isMaterAndMargo,
  isThemeNewYear,
  isFootBallTheme,
  isOkko,
  isSuperpapaTheme,
} from '../../../state/modules/city/selectors';

// Hooks
import {useHatiko} from "../../../components/PromotionHatico/useHatiko";
import {themeNameSpaces} from "../../../state/modules/city/themeNameSpaces";
import {isHalloweenGame, promoGameProps} from "../../../state/modules/promotionGame/selectors";
import { OkkoLink } from '../../../components/Okko/OkkoLink/OkkoLink';
const PromoFootball = lazy(() => import('../../../components/PromotionFootball'));
const SuperpapaLink = lazy(() => import('../../Superpapa/components/SuperpapaLink/SuperpapaLink'));

const cx = classNames.bind(styles);

export default function HomePage(props) {
  const SESSION_STORAGE_KEY_VPN = "modal-vpn";
  const LOCAL_STORAGE_KEY_LOYALTY = "showLoyaltyModal";
  const dispatch = useDispatch();

  const intl = useIntl();
  const location = useLocation();

  const stockRole = props?.stockRole;
  const topRef = useRef(null);
  const widthRef = useRef(null);

  const { isHomeCatalogVisible, categories } = useHome(topRef);

  const openModalVpn = useSelector(state => state.homeModal.isModalVpn);
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const { name, declension } = useSelector(state => state.city.userCity);

  const isNewYearMode = useSelector(isThemeNewYear);
  const isMMGame = useSelector(isMaterAndMargo);
  const isGenderPromoActive = useSelector(isGenderPromo);
  const isOkkoPromoActive = useSelector(isOkko);
  const isFootballActive = useSelector(isFootBallTheme);
  const isSuperpapaActive = useSelector(isSuperpapaTheme);
  const isWaitingForOpenings = useSelector(state => state.city.waitOpenings);
  const {isPromotionGameActive, isHalloweenGame, artefactsIds} = useSelector(promoGameProps);

  const {isHatiko} = useHatiko()
  const currentLoyaltyForOldPL = useSelector(currentLoyaltySelectorForNewPL);
  const daysResetPL = useSelector(daysResetStatus);

  const condition = useMemo(() => {
    return currentLoyaltyForOldPL && daysResetPL > 0 && isAuthenticated;
  }, [currentLoyaltyForOldPL, daysResetPL, isAuthenticated]);

  const [isOpenModalBonus, setIsOpenModalBonus] = useState(false);
  const [isOpenModalAuth, setIsOpenModalAuth] = useState(false);
  const [isOpenModalPL, setIsOpenModalPL] = useState(false);

  const handleOpenModalPL = () => {
    setIsOpenModalPL(true);
  };

  const closeModalVpn = () => {
    sessionStorage.setItem(SESSION_STORAGE_KEY_VPN, "false");
    dispatch(changeVpnModal(false));
  };

  useEffect(() => {
    !window.localStorage.getItem(LOCAL_STORAGE_KEY_LOYALTY) && condition && handleOpenModalPL();
  }, [condition]);

  useEffect(() => {
    sessionStorage.getItem(SESSION_STORAGE_KEY_VPN) !== null &&
    dispatch(changeVpnModal(JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY_VPN))));
  }, []);

  useEffect(() => {
    if (isAuthenticated) dispatch(fetchProfile());
  }, [isAuthenticated]);

  useEffect(() => {
    document.title = intl.formatMessage({ id: "page.menu.helmet" }, { declension, name });
  }, [location.search]);

  return (
    <div className={cx("HomePage", { "HomePage__new-year": isNewYearMode })} ref={widthRef}>
      {isNewYearMode && <div className={cx("HomePage__header")} />}
      {isPromotionGameActive && <FlagsDecor className={cx("HomePage__promoGame-decor")} />}
      {isPromotionGameActive && artefactsIds.includes('body') &&
          <PromoArtefact activeEffect={'toTop'}
                         id={'body'}
                         className={cx('HomePage__promoGame-artefact', {'HomePage__promoGame-artefact_mm': isMMGame})}
          />

      }
      <MainSlider topRef={topRef} view="desktop" />
      <div className={cx("HomePage__container")}>
        <Container>

          {!stockRole && <CategoryIcons className={cx("HomePage__menu")} />}
          <div ref={topRef} style={{ height: "1px" }} />
          {!isWaitingForOpenings && <MainSidebar />}

          <div
            className={cx("HomePage__content", {
              HomePage__content_hidden: !isHomeCatalogVisible,
              HomePage__content_disableMainSideBar: isWaitingForOpenings
            })}
          >
            {stockRole ? (
              <StockGoods stockRole={stockRole} />
            ) : (
              <ProductList
                setIsOpenModalBonus={setIsOpenModalBonus}
                setIsOpenModalAuth={setIsOpenModalAuth}
                category={categories}
                isHiddenActions={isWaitingForOpenings}
              />
            )}
            <SEO />
          </div>
        </Container>
        {!stockRole && <Header />}
        <DeliveryAlert />
        <FreeSaucesPanel />
        <ThanksForOrderingLink />
        <ModalNotAuth isOpenModalAuth={isOpenModalAuth} setIsOpenModalAuth={setIsOpenModalAuth} />
        {isOpenModalPL && (
          <ModalNewLoyaltyProgramm isOpen={isOpenModalPL} setIsOpen={setIsOpenModalPL} />
        )}
        {isWaitingForOpenings && <NotAllowedCityModal/>}
        <ModalNotEnough
          title={intl.formatMessage({ id: "profile.papabonuses.notEnough" })}
          text={intl.formatMessage({ id: "profile.papabonuses.payment" })}
          isOpenModalBonus={isOpenModalBonus}
          setIsOpenModalBonus={setIsOpenModalBonus}
        />
        {openModalVpn && !isOpenModalPL && (
          <PopupGeneral
            text={intl.formatMessage({ id: "vpn.modal" })}
            icon={<VPNIcons />}
            closeModal={closeModalVpn}
            className={cx("ModalVpn__popupMobileGeneral_desktop")}
            classNameIcon={cx("ModalVpn__popupMobileGeneral_icon")}
          />
        )}

        {isPromotionGameActive && <PopupPromoGame
          className={cx("HomePage__PopupPromoGame")}
        />}
        {isHatiko && <HatikoBanner/>}
        {isGenderPromoActive && <GenderLink />}
        {isOkkoPromoActive && <OkkoLink />}
        {isFootballActive &&
          <Suspense fallback={<>Loading...</>}>
            <PromoFootball/>
          </Suspense>
        }
        {isSuperpapaActive && (
          <Suspense fallback={<>{null}</>}>
            <SuperpapaLink />
          </Suspense>
        )}

      </div>
    </div>
  );
}

HomePage.defaultProps = {
  stockRole: ""
};

HomePage.propTypes = {
  stockRole: PropTypes.oneOf(["conditions", "rewards", ""])
};
