import React, { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import CategoryNavItem from '../../../components/CategoryNavItem';
import { HaticoCategoryItem } from '../../../components/PromotionHatico/HaticoCategoryItem';

// Icons
import PizzaData from './assets/menu/pizza.svg';
import NapitkiData from './assets/menu/drinks.svg';
import ZakuskiData from './assets/menu/snacks.svg';
import ComboboxData from './assets/menu/combobox.svg';
import DesertyData from './assets/menu/dessert.svg';
import SalatsData from './assets/menu/salads.svg';
import SousesData from './assets/menu/sauces.svg';
import OtherData from './assets/menu/other.svg';
import HotData from './assets/menu/hot.svg';
import PapadiasData from './assets/menu/papadias.svg';
import VeganData from './assets/menu/vegan.svg';
import PumpkinData from './assets/menu/pumpkin.png';
import NewYear from './assets/menu/newYear.svg';
import Fire from './assets/menu/fire.svg';
import promoDecorHat from '../../../assets/FoolDay_images/hat.png';
import pizzaCosmo from '../../../assets/Astronaut_images/pizza.svg';
import Post from './assets/menu/post.svg';
import Kids from './assets/menu/kids.svg';
import Breakfast from './assets/menu/breakfast.svg';
import Autumn from './assets/menu/autumn.svg';

// Styles
import styles from './CategoryIcons.styl';
import { useHatiko } from '../../../components/PromotionHatico/useHatiko';
import { themeNameSpaces } from '../../../state/modules/city/themeNameSpaces';
import { isAstronautTheme, isMovieBorderTheme } from '../../../state/modules/city/selectors';
import PromotionMovieBorderDecor from '../../../components/PromotionMovieBorder/PromotionMovieBorderDecor';

const cx = classNames.bind(styles);

const iconsData = {
  pizza: PizzaData,
  napitki: NapitkiData,
  zakuski: ZakuskiData,
  salaty: SalatsData,
  deserty: DesertyData,
  sauce: SousesData,
  combo: ComboboxData,
  combobox: ComboboxData,
  other: OtherData,
  hot: HotData,
  papadias: PapadiasData,
  vegan: VeganData,
  dnitykvy: PumpkinData,
  hits: Fire,
  lenten: Post,
  'new-year': NewYear,
  kids: Kids,
  breakfast: Breakfast,
  autumn: Autumn,
};

export default function CategoryIcons(props) {
  const { className } = props;

  const categoriesAliases = useSelector(state => state.catalog.products.categoriesAliases);
  const shownCategories = useSelector(state => state.catalog.products.categories)
    .filter(item => item?.is_hidden === 0)
    .map(item => item.alias.toLowerCase());
  const result = {};
  shownCategories.forEach(item => {
    if (categoriesAliases[item]) {
      result[item] = categoriesAliases[item];
    }
  });
  const { isPromotionGameActive, gameName } = useSelector(state => state.promotionGame);
  const { isHatiko } = useHatiko();
  const isAstronaut = useSelector(isAstronautTheme);
  const isMovieBorder = useSelector(isMovieBorderTheme);

  const isFoolDayGame = useRef(gameName === themeNameSpaces.FOOLS_DAY).current;

  const getIcon = useCallback(
    alias => {
      if (isAstronaut && alias === 'pizza') {
        return pizzaCosmo;
      }
    },
    [isAstronaut],
  );

  return (
    <div className={cx('CategoryIcons', className)}>
      {Object.keys(result).map(alias => (
        <CategoryNavItem
          key={alias}
          className={cx('CategoryIcons__link', `CategoryIcons__link_${alias}`)}
          alias={alias}
          style={{ width: `${100 / Object.keys(result).length}%` }}
          id={`nav_${alias}`}
        >
          <img
            className={cx('CategoryIcons__icon', `CategoryIcons__icon_${alias}`)}
            alt={alias}
            src={(isAstronaut && getIcon(alias)) || iconsData[alias] || iconsData.pizza}
          />
          <span className={cx('CategoryIcons__name')}>
            {isHatiko && alias === 'combo' ? (
              <HaticoCategoryItem word={result[alias]} />
            ) : (
              result[alias]
            )}
          </span>
        </CategoryNavItem>
      ))}
      {isPromotionGameActive && isFoolDayGame && (
        <div className={cx('CategoryIcons__promoGame-decor')}>
          <img src={promoDecorHat} className={cx('Cart__promoGame-img')} alt="circus" />
        </div>
      )}
      {isMovieBorder && (
        <PromotionMovieBorderDecor srcView={'1'} className={cx('CategoryIcons__promoBorder')} />
      )}
    </div>
  );
}

CategoryIcons.defaultProps = {
  className: '',
};

CategoryIcons.propTypes = {
  className: PropTypes.string,
};
