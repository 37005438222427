import React from 'react';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import KeyAuthorization from '../../../../icons/keyAutorization.svg';
import { useIntl } from 'react-intl';
import classNames from 'classnames/bind';

//Styles
import styles from './ModaNotAuth.styl';
import { useDispatch } from 'react-redux';
import { toggleAuthPopup } from '../../../../state/modules/ui/actions';

const cx = classNames.bind(styles);

const ModalNotAuth = ({ isOpenModalAuth, setIsOpenModalAuth }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const onCloseModalAuth = () => {
    setIsOpenModalAuth(false);
  };
  const onClickAuthHandler = () => {
    setIsOpenModalAuth(false);
    dispatch(toggleAuthPopup(true));
  };

  return (
      <Modal
        className={cx("ModalContainer__modal-auth")}
        isOpen={isOpenModalAuth}
        onClose={onCloseModalAuth}
      >
        <div className={cx("ModalContainer__picture")}>
          <KeyAuthorization width={150} height={150} />
        </div>
        <span className={cx("ModalContainer__header")}>
                      {intl.formatMessage(
                        { id: "authorization" }
                      )}
        </span>
        <span className={cx("ModalContainer__text")}>
                      {intl.formatMessage(
                        { id: "profile.papabonuses.authorization" }
                      )}
        </span>
        <Button className={cx('ModalContainer__order-button-auth')}
                onClick={onClickAuthHandler}>
          {intl.formatMessage({ id: 'signIn' })}
        </Button>
      </Modal>
  );
};

export default ModalNotAuth;
