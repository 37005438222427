import React from "react";
import Modal from "../../../../components/Modal";
import LollipopBlack from "../../../../icons/lollipopBlack.svg";
import Button from "../../../../components/Button";
import { useIntl } from "react-intl";
import classNames from "classnames/bind";

//Styles
import styles from "./ModalNotEnough.styl";
import { getRoute, paths } from "../../../../entry/routes";

const cx = classNames.bind(styles);

const ModalNotEnough = ({ title, text, isOpenModalBonus, setIsOpenModalBonus }) => {
  const intl = useIntl();

  const onCloseModalBonus = () => {
    setIsOpenModalBonus(false);
  };

  return (
    <Modal
      className={cx("ModalContainer__modal")} isOpen={isOpenModalBonus} onClose={onCloseModalBonus}>
      <div className={cx("ModalContainer__picture")}>
        <LollipopBlack width={151} height={151} />
      </div>
      <span className={cx("ModalContainer__header")}>{title}</span>
      <span className={cx("ModalContainer__text")}>{text}</span>
      <Button to={getRoute(paths.papabonus)} className={cx("ModalContainer__order-button")}>
        {intl.formatMessage({ id: "seo.text.more" })}
      </Button>
    </Modal>
  );
};

export default ModalNotEnough;
