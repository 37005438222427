import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";

// Components
import Heading from "../../../../components/Heading";
import ProductCard from "../../../../components/ProductCard";
import CreatePizzaCard from "../../../../components/CreatePizzaCard/DesktopView";


// Partials
import useStockGoods from "../useStockGoods";

// Styles
import styles from "./StockGoods.styl";
import { useSelector } from "react-redux";

const cx = classNames.bind(styles);

export default function StockGoods(props) {
  const stockRole = props?.stockRole;

  const isLoadingStockApply = useSelector(state => state?.stock?.isLoadingStockApply);

  const {
    stockGoodsRef,
    title,
    filtredGoods,
    removeIngredientAction,
    cancelRemovedIngredientsAction,
    addToCart,
    type
  } = useStockGoods({ stockRole });

  return (
    <section className={cx("StockGoods")} ref={stockGoodsRef}>
      <Heading className={cx("StockGoods__heading")} level={2} tagName="h4">
        {title}
      </Heading>
      <div className={cx("StockGoods__list")}>
        {filtredGoods.map(good => {
          const isConstructor = good.alias.startsWith('sozda')

          if (isConstructor){
            return (
              <CreatePizzaCard
                key={good.alias}
                className={cx("StockGoods__item")}
                name={good.name}
                id={good.id}
                category={good.category}
              />
            );
          }

          return (
            <ProductCard
              isLoadingStockApply={isLoadingStockApply}
              className={cx("StockGoods__item")}
              key={good.alias}
              good={good}
              stockRole={stockRole}
              removeIngredientAction={removeIngredientAction}
              cancelRemovedIngredientsAction={cancelRemovedIngredientsAction}
              addToCart={addToCart}
              type={type}
            />
          )
        })}
      </div>
    </section>
  );
}

StockGoods.propTypes = {
  stockRole: PropTypes.oneOf(["conditions", "rewards"]).isRequired
};
