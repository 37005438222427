import React, { useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import Scrollspy from 'react-scrollspy';
import { getRoute, paths } from '../../../../entry/routes';
// Selectors
import {useHatiko} from "../../../../components/PromotionHatico/useHatiko";

// Components
import CategoryNavItem from '../../../../components/CategoryNavItem';
import AuthModule from '../../../../components/AuthModule';
import LangSwitcher from '../../../../components/LangSwitcher/DesktopView';
import Nav from '../../../../components/Nav';
import Container from '../../../../components/Container';
import Logo from '../../../../components/Logo';
import {HaticoCategoryItem} from "../../../../components/PromotionHatico/HaticoCategoryItem";

// Icons
import CrossIcon from '../../../../icons/cross.svg';

// Styles
import styles from './Header.styl';

const cx = classNames.bind(styles);

export default function Header() {
  const {isHatiko} = useHatiko()

  const categories = useSelector(state => state.catalog.products.categories)?.filter(item => item?.is_hidden === 0);
  const isHomeCategoriesStick = useSelector(state => state.ui.isHomeCategoriesStick);
  const isOpen = useSelector(state => state.ui.isAuthPopupOpen);
  const [isStickyNavOpen, setIsStickyNavOpen] = useState(false);

  const onStickyNavToggle = () => {
    setIsStickyNavOpen(prev => !prev);
  };

  const scrollToTop = () => {
    window.history.pushState({}, '', getRoute(paths.home));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (isOpen) {
      setIsStickyNavOpen(true);
    }
  }, [isOpen]);

  return (
    <Transition
      in={isHomeCategoriesStick}
      timeout={{ enter: 0, exit: 350 }}
      mountOnEnter
      unmountOnExit
    >
      {state => (
        <header className={cx('Header', `Header_${state}`)} id="Header_sticky">
          <Container className={cx('Header__container')}>
            <button className={cx('Header__logo')} type="button" onClick={scrollToTop}>
              <Logo />
            </button>
            {isStickyNavOpen ? (
              <>
                <Nav view="desktop" />
                <AuthModule />
              </>
            ) : (
              <Scrollspy
                className={cx('Header__categories')}
                items={categories.map(category => category.alias)}
                currentClassName={cx('Header__category_active')}
                offset={-10}
                componentTag="div"
              >
                {categories.map(c => (
                  <CategoryNavItem key={c.alias} className={cx('Header__category')}
                                   alias={c.alias}
                                   style={{fontSize: categories.length > 9 && 12}}
                  >
                    {isHatiko && c.alias === 'combo' ? <HaticoCategoryItem word={c.name}/> : c.name}
                  </CategoryNavItem>
                ))}
              </Scrollspy>
            )}
            <LangSwitcher />
            <button onClick={onStickyNavToggle} className={cx('Header__hamburger')} type="button">
              {isStickyNavOpen ? (
                <CrossIcon />
              ) : (
                <>
                  <span className={cx('Header__hamburger-line')} />
                  <span className={cx('Header__hamburger-line')} />
                  <span className={cx('Header__hamburger-line')} />
                </>
              )}
            </button>
          </Container>
        </header>
      )}
    </Transition>
  );
}
