import React, { useCallback, useState } from 'react';

import { useIntl } from 'react-intl';

import classNames from 'classnames/bind';
import Discount from '../../../../icons/discount.svg';

import SignUpForm from './SignUpForm';

// Styles
import styles from './DesktopView.styl';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import Heading from '../../../../components/Heading';

const cx = classNames.bind(styles);

export const NotAllowedCityModal = () => {
  const [showModal, setShowModal] = useState(true);
  const intl = useIntl();

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <div className={cx('NotAllowedCityModal')}>
      <Button className={cx('NotAllowedCityModal__button')} onClick={() => setShowModal(true)}>
        <div className={cx('NotAllowedCityModal__button-wrapper')}>
          <Discount width={20} height={22} />
          {intl.formatMessage({ id: 'page.landing.modal.button' })}
        </div>
      </Button>

      {showModal && (
        <Modal className={cx('NotAllowedCityModal__Modal')} isOpen={showModal} onClose={closeModal}>
          <div className={cx('NotAllowedCityModal__content')}>
            <Heading tagName="h3" className={cx('NotAllowedCityModal__content_heading')}>
              {intl.formatMessage({ id: 'page.landing.title' })}
            </Heading>
            <p className={cx('NotAllowedCityModal__content_text')}>
              {intl.formatMessage({ id: 'page.landing.discount' })}
            </p>
            {/*<p*/}
            {/*  className={cx(*/}
            {/*    'NotAllowedCityModal__content_text',*/}
            {/*    'NotAllowedCityModal__content_text-marginTop',*/}
            {/*  )}*/}
            {/*>*/}
            {/*  {intl.formatMessage({ id: 'page.landing.signup.needed' })}*/}
            {/*</p>*/}
          </div>

          <SignUpForm
            onSuccessCallback={() => {
              setShowModal(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
};
